import React from "react";

import ImageGallery from 'react-image-gallery';
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';

const images = [
    {
        original: 'http://2toast.com/sketches/Adamus.jpg',
        thumbnail: 'http://2toast.com/sketches/AdamusT.jpg',
    },
    {
        original: 'http://2toast.com/sketches/PsionicGhostwise.jpg',
        thumbnail: 'http://2toast.com/sketches/PsionicGhostwiseT.jpg',
    },
    {
        original: 'http://2toast.com/sketches/TMNTAdvark.jpg',
        thumbnail: 'http://2toast.com/sketches/TMNTAdvarkT.jpg',
    },
    {
        original: 'http://2toast.com/sketches/Splerrrrg.jpg',
        thumbnail: 'http://2toast.com/sketches/SplerrrrgT.jpg',
    },
    {
        original: 'http://2toast.com/sketches/Twilk.jpg',
        thumbnail: 'http://2toast.com/sketches/TwilkT.jpg',
    },
    {
        original: 'http://2toast.com/sketches/Unicorn.jpg',
        thumbnail: 'http://2toast.com/sketches/UnicornT.jpg',
    },
    {
        original: 'http://2toast.com/sketches/SlayerArtificer.jpg',
        thumbnail: 'http://2toast.com/sketches/SlayerArtificerT.jpg',
    },
];

class SketchbookGallery extends React.Component {
    render() {
        return <ImageGallery items={images} showPlayButton={false} />
    }
}

export default SketchbookGallery;

