import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ProductCard from "./components/ProductCard";
import SketchbookGallery from "./components/SketchbookGallery";
import ContactMe from './components/ContactMe';


import 'bootstrap/dist/css/bootstrap.min.css';


import TooToastLogo from './assets/svg/logo.svg';



export default function App() {
    return (
        <Router>
            <Container fluid>
                <Row>
                    <Col>
                        <div className="logo">
                            <Switch>
                                <Route path="/about">
                                    <NavBar />
                                </Route>
                                <Route path="/contact">
                                    <NavBar />
                                </Route>
                                <Route path="/sketchbook">
                                    <NavBar />
                                </Route>
                                <Route path="/products">
                                    <NavBar />
                                </Route>
                                <Route path="/">
                                    <HomeTop />
                                    <HomeMotto />
                                    <HomeCards />
                                </Route>

                            </Switch>

                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>


                            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                            <Switch>
                                <Route path="/about">
                                    <About />
                                </Route>
                                <Route path="/products">
                                    <Products />
                                </Route>
                                <Route path="/contact">
                                    <Contact />
                                </Route>
                                <Route path="/sketchbook">
                                    <Sketchbook />
                                </Route>
                                <Route path="/">
                                    <Home />
                                </Route>
                            </Switch>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Router>
    );
}

function NavBar() {
    return (
        <Navbar expand="lg">
            <Navbar.Brand href="/">
                <div className="logo">
                    <img src={TooToastLogo} alt="2Toast Logo" />
                </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/products">Products</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                    <Nav.Link href="/sketchbook">Sketchbook</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

function HomeTop() {
    return (
        <div>
            <img src={TooToastLogo} alt="React Logo" />
        </div>
    )
}

function HomeMotto() {
    return (
        <div className={"motto"}>
            Role Playing Game Character Art by 2Toast
        </div>
    )
}

function HomeCards() {
    return (
        <div className={"cards"}>
            <Row>
                <Col sm={12} md={3}>
                    <Card>
                        <Card.Body>
                            <a href={"/about"}>
                                <Card.Title>About this Site </Card.Title>
                                <Card.Text>
                                    The purpose of this site is to connect with RPGers that would like to buy custom art based on their characters. Also, I wanted to learn React, the technology  the site was made with.
                                </Card.Text>
                            </a>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={3}>
                    <Card>
                        <Card.Body>
                            <a href={"/products"}>
                                <Card.Title>Products</Card.Title>
                                <Card.Text>
                                    Given how hard it is to manage expectations in the art commission world, I am offering 3 distinct and defined products for people to buy. Click here to see some examples and find out more.
                                </Card.Text>
                            </a>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={3}>
                    <Card>
                        <Card.Body>
                            <a href={"/contact"}>
                                <Card.Title>Contact</Card.Title>
                                <Card.Text>
                                    Please contact me with any interest for art. The site is still under development, so come back from time to time to see more work added to the sketchbook and more products.
                                </Card.Text>
                            </a>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={3}>
                            <Card>
                        <Card.Body>
                            <a href={"/sketchbook"}>
                                <Card.Title>Sketchbook</Card.Title>
                                <Card.Text>
                                    My RPG art doesn't match the products offered. L ook through my sketchbook to see more of my style. When reaching out, reference sketches you like so I may get a better idea of what you are after.
                                </Card.Text>
                            </a>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

function Home() {
    document.body.classList.add('home');
    document.body.classList.remove('about');
    document.body.classList.remove('contact');
    document.body.classList.remove('sketchbook');

    return (
        <div>

        </div>
    )

}

function About() {
    document.body.classList.add('about');
    document.body.classList.remove('home');
    document.body.classList.remove('contact');
    document.body.classList.remove('sketchbook');
    return (
        <div>
            <h2>About</h2>
            <p>I have been playing D&D since I was in grade school over 35 years ago, on a stoop in Queens with my brother and our block friends. Since then I have played other RPGs across other genres, but D&D still holds the same magic for me it did back then.</p>
            <p>I love drawing people's RPG characters, so I put together this simple site to see if I can make some beer money while having some fun. Please use the contact form to reach out to me with feedback, questions or interest. I would love to hear from you and get back  to you.</p>
        </div>
    )

}

function Products() {
    document.body.classList.add('products');
    document.body.classList.remove('about');
    document.body.classList.remove('home');
    document.body.classList.remove('contact');
    document.body.classList.remove('sketchbook');
    return (
        <div>
            <h2>Products</h2>
            <p>Over the years, I have sketched my D&D (as well as other RPGs) characters in a variety of poses, backgrounds and contexts. For the commission service I am providing with this website, I will be narrowing down the work I do into discrete products to better manage expectations and level of effort.
            </p>
            <p> My art products have an option of 3 different levels of rendering with different prices. </p>
                <ul>
                    <li><strong>Pencil Sketch.</strong> My work starts of with gestures and sketching on on paper which I then scan, clean up and deliver in as a high resolution digital file.</li>
                    <li><strong>Digitally Inked.</strong> A digitally inked piece with typography where appropriate, suitable for coloring for those that want to work on it further.</li>
                    <li><strong>Digitally Colored.</strong> Delivered on a textured background as a high resolution digital file.
                    </li></ul>

            <div className={"product"}>
                <ProductCard prodid="xamo" imageName="XamôdæsColor" imageAlt="Example of Character plus Gear, inked and colored" productName="Character With Gear"/>
            </div>
            <div className={"product"}>
                <div className={"product-card"}>
                    <Row>
                        <Col>
                            <h5>Character Before and After <span className={"comming-soon"}>(coming soon)</span></h5>
                            <p>Often times a character will change drastically during the course of a campaign and its fun to juxtapose 2 different times in a character's life.</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={"product"}>
                <div className={"product-card"}>
                    <Row>
                        <Col>
                            <h5>Party Draw <span className={"comming-soon"}>(coming soon)</span></h5>
                            <p>The entire party together!</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )

}

function Contact() {
    document.body.classList.add('contact');
    document.body.classList.remove('about');
    document.body.classList.remove('home');
    document.body.classList.remove('sketchbook');
    return (
        <div>
            <h2>Contact</h2>
            <ContactMe />
        </div>
    )
}

function Sketchbook() {
    document.body.classList.add('sketchbook');
    document.body.classList.remove('about');
    document.body.classList.remove('contact');
    document.body.classList.remove('home');
    return (
        <div>
            <h2>Sketchbook</h2>
            <SketchbookGallery />
        </div>
    )
}

