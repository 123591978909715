import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './ProductCard.css';
import ProductArt from "./ProductArt"
import XamôdæsFullColor from '../assets/XamôdæsColor1000.png'
import XamôdæsBW from '../assets/XamôdæsBW.png'
import WardukeSketch from '../assets/WardukeSketch.png'

class ProductCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {value: 'color'};

        this.productName = props.productName;
        this.prodid = props.prodid;
        this.imageAlt = props.imageAlt;


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});

    }

    handleSubmit(event) {
        alert('Your favorite flavor is: ' + this.state.value);
        event.preventDefault();
    }

    render() {
        return (
            <div className={"product-card"}>
                <Row>
                    <Col>
                        <h5>{this.productName}</h5>
                        <form onSubmit={this.handleSubmit}>
                            <label>
                                <span style={{marginRight: 1 + 'em'}}>Use the select to toggle through the different render levels.</span>
                                <select value={this.state.value} onChange={this.handleChange}>
                                    <option value="pencil">Pencil $20</option>
                                    <option value="ink">Digital Ink $40</option>
                                    <option value="color">Digital Color $100</option>
                                </select>
                            </label>
                            <input type="hidden" value="Submit"/>
                        </form>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ProductArt renderLevel={this.state.value} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><em>A rendering of my Githyanki Abjuration Wizard I played in a Descent into Avernus campaign.</em></p>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default ProductCard;


