import React from 'react';
import ReactDOM from 'react-dom';



class ContactMe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            contact_email: '',
            interest: '',
            message: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(target);

        this.setState({
            [name]: value
        });

    }

    handleSubmit(event) {
        if (this.state.interest === 'Please Choose') {
            alert('Please choose what your interest is. Choose N/A if there is no applicable option.')
        }
        else {

            event.preventDefault();
            alert('Your message has been sent.')

            var body = JSON.stringify(this.state);

            fetch('https://2toast.com/email.php', {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: body
            }).then(function(response) {
                console.log(response);
            });
            this.state = {
                contact_email: '',
                interest: '',
                message: '',
            };
            this.refs.field1.value="";
            this.refs.field2.value="";
            this.refs.field3.value="";
        }

    }

    render() {

        const { contact_email, interest, message } = this.state;
        return (

            <form onSubmit={this.handleSubmit} >
                <div className="form-group">
                    <label htmlFor="contact_email">Email address (so I can get back to you)</label>
                    <input type="email" className="form-control" name="contact_email" required
                           placeholder="your@email.com" onChange={this.handleInputChange} value={contact_email} ref="field1" />
                </div>

                <div className="form-group">
                    <label htmlFor="interest">I am interested in</label>
                    <select className="form-control" name="interest" onChange={this.handleInputChange} placeholder="Choose your interest" value={interest} ref="field2" >
                        <option>Please Choose</option>
                        <option>Pencil Sketch</option>
                        <option>Inked Drawing</option>
                        <option>Inked & Colored</option>
                        <option>Custom</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="message">Your message</label>
                    <textarea className="form-control" name="message" rows="5" onChange={this.handleInputChange} value={message} ref="field3" />
                </div>
                <button>Send this message to 2Toast</button>
            </form>

        );
    }
}



export default ContactMe;
