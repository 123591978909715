import React from 'react';

import color from '../assets/XamColor928.jpg'
import ink from '../assets/XamBW928.jpg'
import pencil from '../assets/XamSketch928.jpg'


function ProductArt(props) {

    if (props.renderLevel === "pencil") {
        return (
            <picture>
                <img src={pencil} alt={props.imageAlt} />
            </picture>
        );
    }
    else if (props.renderLevel === "ink") {
        return (
            <picture>
                <img src={ink} alt={props.imageAlt} />
            </picture>
        );
    }
    else {
        return (
            <picture>
                <img src={color} alt={props.imageAlt} />
            </picture>
        );
    }

}

export default ProductArt;
